<template>
  <div class="detail">    
    <div class="img">
      <template v-for="(item,index) in urlList">
        <img :key='index' :src='item' width="100%">
      </template>      
    </div>
  </div>
</template>
<script>
export default {
  props:{
    fileUrl: {
      type:String,
      default:''
    }, // 文件地址   
  },
  data() {
    return {
     urlList:[],
    };
  },
  mounted() {
    this.urlList=this.fileUrl.split(';');
    console.log(this.urlList)
  },
  methods: {
  }
};
</script>
<style  scoped>
.detail {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.img{
  padding-top: 50px;
}

</style>