<template>
  <div>
    <meet-title :meetName="infos.meetTitle" :meetDate="meetDate" />

    <ul class="infoBox">
      <template v-for="(item, index) in list">
        <li :key='index' v-if="item.key=='fileUrlH5'" class='spaceLi'>
          <span class="infoName">{{ item.name }}</span>
          <div class="see"  @click="showFile"><span>查看</span><Icon name="arrow" color='#1989fa' size='14'/></div>
        </li>
        <li :key="index" v-else>
          <span class="infoName">{{ item.name }}</span>
          <span class="infoText">{{ item.value }}</span>
        </li>
      </template>
    </ul>
    <Popup
        v-model="showPop"
        closeable
        position="bottom"
        :style="{ height: '100vh'}"
      >
      <div style="height:100vh;overflow-y:auto">
        <PreviewFile v-if='showPop' :fileUrl='infos.fileUrlH5'/>
      </div>
    </Popup>
  </div>
</template>

<script>
import MeetTitle from "./component/meetTitle.vue";
import PreviewFile from "./previewFile.vue"
import { Icon ,Toast,Popup} from 'vant';
export default {
  name: "meetIntroduce",
  components: {
    MeetTitle,
    Icon,
    Toast,
    Popup,
    PreviewFile
  },
  data() {
    return {
      showPop:false,
     infos:{},
      list: [
        {
          name: "报名日期：",
          key: "signUpDate",
          value: "",
        },
        { name: "培训日期：", key: "meetDate", value: "" },
        { name: "缴费金额：", key: "payAmount", value: "0元" },
        { name: "人数上限：", key: "maxUser", value: "" },
        {
          name: "培训地点：",
          key: "address",
          value: "",
        },
        {
          name: "报到地点：",
          key: "signUpAddress",
          value: "",
        },
        {
          name: "培训介绍：",
          key: "meetDescribe",
          value:"",
        },
        {
          name: "培训通告：",
          key: "fileUrlH5",
          value:"",
        },
      ],
    };
  },
  computed:{
     meetDate(){
       let str=this.infos.meetTimeStart.split('-').join('/')+"-"+this.infos.meetTimeEnd.split('-').join('/')
       return str;
     }
  },
  created(){
    this.infos=this.$route.params;
    let infos=this.$route.params;
    this.list.map(item=>{
      if(item.key=='signUpDate'){
        item.value=infos.applyTimeStart.split('-').join('/')+'-'+infos.applyTimeEnd.split('-').join('/')
      }else if(item.key=='meetDate'){
        item.value=infos.meetTimeStart.split('-').join('/')+"-"+infos.meetTimeEnd.split('-').join('/')
      }else if(item.key=='address'){
        item.value=(infos.meetAddressName|| '')+infos.meetDetailedAddress
      }else if(item.key=='signUpAddress'){
        item.value=(infos.reportAddressName|| '')+infos.reportDetailedAddress
      }else if(item.key=='payAmount'){
        item.value=infos.payAmount+' 元'
      }else if(item.key=='maxUser'){
        item.value=infos.maxUser+' 人'
      }else{
        item.value=infos[item.key]
      }
    })
  },
  methods: {
    showFile(){
      if(!this.infos.fileUrlH5){
        Toast('暂无培训通告');
      }else{
        this.showPop=true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.infoBox {
  margin-top: -0.2rem;
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 0.1rem 0.25rem 0.2rem;
  li {
    margin-top: 0.3rem;
    font-size: 14px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      line-height: 20px;
      display: inline-block;
    }
    .infoName {
      width: 70px;
    }
    .infoText {
      width: calc(~"100% - 70px");
      color: #333;
    }
  }
  .spaceLi{
    justify-content:space-between;
    align-items: center;
    .see{
      display: flex;
      flex-direction: row;
      align-items: center;
      span{
        color: #1989fa;
        margin-right: 5px;
      }
    }
  }
}
</style>
